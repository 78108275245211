












import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Reporting from '../components/Reporting.vue';
import { apiGet } from '@/store';
import { setLocale } from '@/i18n';

@Component({
  components: {
    Reporting,
  },
})
export default class ExternalReporting extends Vue {
  @Prop() public reportHash!: string;

  public media: string = 'pdf';
  public resp: any = null;
  public ready: boolean = false;
  public trackUpdate: boolean = false;
  public loaded: boolean = false;

  public created() {
    document.onreadystatechange = () => {
      this.checkAllDone();
    };

    if (this.$route.query.media === 'email') {
      this.media = 'email';
    }

    if (this.$route.query.lang) {
      setLocale(this.$route.query.lang as string);
    }

    const url = `/external/reporting`;
    const params = {
      report_hash: this.reportHash,
    };
    apiGet(url, params, true).then((response: any) => {
      this.trackUpdate = true;
      console.log('TTT', JSON.stringify(response));
      this.resp = response;
      this.ready = true;
    });
  }

  public updated() {
    console.log('TTT updated called!', this.trackUpdate, document.readyState);
    if (this.trackUpdate) {
      console.log('TTT -> track update');
      this.$nextTick(() => {
        this.loaded = true;
        this.checkAllDone();
      });
    }
  }

  private checkAllDone() {
    console.log('TTT checkAllDone', this.loaded, document.readyState);
    if (this.loaded && document.readyState === 'complete') {
      window.status = 'ready';
      console.log('TTT -> rendering done.');
    }
  }
}
